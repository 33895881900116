<template>
	<div>
		<div class="banner-box">
			<div class="banner" v-for="(item,index) in date" :key="index" @click="jump(item.id)">
				<div class="img">
					<img :src="item.imgUrl" alt="">
				</div>
				<div class="br-box">
					<div class="title">{{item.title}}</div>
					<div class="time">[{{item.publishTime | format}}]</div>
					<div class="brief" v-html="getText(item.content)"></div>
				</div>
			</div>
			<!-- <div class="banner">
				<img src="https://lanhu.oss-cn-beijing.aliyuncs.com/psdc5mji2h49mfp5n5zlcad9vce9zvjaj5s33e5076f-97f0-4b89-b040-a8635fc21628" alt="">
				<div class="br-box">
					<div class="title">国际滑联花样滑冰大奖赛总决赛赛事八分一决赛晋级8强</div>
					<div class="time">[2023-8-17]</div>
					<div class="brief">12月20日，在中国首都北京矩形的国际滑冰大奖赛总决赛八分之一决赛晋级8强，晚上8点正式举行</div>
				</div>
			</div>
			<div class="banner">
				<img src="https://lanhu.oss-cn-beijing.aliyuncs.com/psdc5mji2h49mfp5n5zlcad9vce9zvjaj5s33e5076f-97f0-4b89-b040-a8635fc21628" alt="">
				<div class="br-box">
					<div class="title">国际滑联花样滑冰大奖赛总决赛赛事八分一决赛晋级8强</div>
					<div class="time">[2023-8-17]</div>
					<div class="brief">12月20日，在中国首都北京矩形的国际滑冰大奖赛总决赛八分之一决赛晋级8强，晚上8点正式举行</div>
				</div>
			</div>
			<div class="banner">
				<img src="https://lanhu.oss-cn-beijing.aliyuncs.com/psdc5mji2h49mfp5n5zlcad9vce9zvjaj5s33e5076f-97f0-4b89-b040-a8635fc21628" alt="">
				<div class="br-box">
					<div class="title">国际滑联花样滑冰大奖赛总决赛赛事八分一决赛晋级8强</div>
					<div class="time">[2023-8-17]</div>
					<div class="brief">12月20日，在中国首都北京矩形的国际滑冰大奖赛总决赛八分之一决赛晋级8强，晚上8点正式举行</div>
				</div>
			</div> -->
		</div>
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="12" :pageSize="12"></pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				total: 0,
				date: [],
				pageNum: 1,
				pageSize: 12,
			}
		},
		created() {
			this.getArticleListById()
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			currentchange(e) {
				this.pageNum = e
				this.getArticleListById()
			},
			getArticleListById() {
				console.log(1212121)
				let data = {
					id: this.$route.query.navid,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
				}
				this.$api.getArticleListById(data).then(res => {
					this.total = res.data.data.total
					this.date = res.data.data.list

					this.date.forEach(item => {
						item.title = item.title.replace(/<br>/g, ' ')
					})
				})
			},
			getText(str) {
				const regExp = /<[^>]+>/g; // 匹配所有HTML标签
				const plainText = str.replace(regExp, ''); // 去除所有标签
				return plainText
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/enversion/details',
					query: {
						subjectid: id,
						logo: this.$route.query.logo,
						navindex: this.$route.query.navindex,
						id: this.$route.query.id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
		}
	}
</script>

<style scoped lang="scss">
	.banner-box {
		width: 1200px;
		margin: 0 auto;
		margin-top: 75px;
		display: flex;
		flex-wrap: wrap;

		.banner {
			width: 384px;
			height: 472px;
			background: #F0F4F6;
			margin-right: 24px;
			margin-bottom: 44px;
			cursor: pointer;

			.img {
				width: 384px;
				height: 280px;
				background: #F0F4F6;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
				}

				img:hover {
					transform: scale(1.15);
				}
			}

			.br-box {
				padding: 0 19px;

				.title {
					font-size: 21px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-top: 21px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					line-height: 30px;
					height: 60px;
				}

				.title:hover {
					color: #0091FF;
				}

				.time {
					height: 12px;
					font-size: 13px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					opacity: 0.54;
					margin-top: 15px;
				}

				.brief {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					opacity: 0.65;
					margin-top: 15px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					line-height: 30px;
					
				}
			}
		}

		.banner:nth-child(3n) {
			margin-right: 0;
		}
	}

	.paging {
		width: 1200px;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		padding-bottom: 73px;
	}
</style>