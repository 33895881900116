<template>
	<div>
		<div class="sponsor">
			<div class="title">Official Sponsors</div>
			<div class="xian"></div>
			<div class="box">
				<div class="pp" v-for="(item,index) in sponsor" :key="index" @click="Jump(item)">
					<img :src="item.imgUrl" alt="">
				</div>
			</div>
		</div>
		<div class="sponsor">
			<div class="title" style="margin-top: 27px;">Suppliers</div>
			<div class="xian"></div>
			<div class="box">
				<div class="pp2" v-for="(item,index) in supplier" :key="index" @click="Jump(item)">
					{{item.name}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				sponsor:[],
				supplier:[]
			}
		},
		created() {
			this.findList()
		},
		methods: {
			findList() {
				this.$api.getFriendLink({type:1}).then(res => {
					this.sponsor = res.data.data
				})
				this.$api.getFriendLink({type:2}).then(res => {
					this.supplier = res.data.data
				})
			},
			Jump(e) {
				if (e.pathUrl == null || e.pathUrl == '') return
				window.open(e.pathUrl, '_blank');
			}
		}
	}
</script>

<style scoped lang="scss">
	.sponsor {
		.title {
			font-size: 38px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			width: 1200px;
			margin: 0 auto;
			text-align: center;
			margin-top: 58px;
		}
	
		.xian {
			width: 83px;
			height: 8px;
			background: #0091FF;
			border-radius: 4px;
			margin: 0 auto;
			text-align: center;
			margin-top: 21px;
		}
	
		.box {
			margin-top: 44px;
			display: flex;
			width: 1301px;
			flex-wrap: wrap;
			margin: 44px auto 0;
	
			.pp {
				width: 302px;
				height: 149px;
				background: #FFFFFF;
				border: 1px solid #C5F3FF;
				margin-right: 31px;
				margin-bottom: 35px;
				cursor: pointer;
	
				img {
					width: 100%;
					max-width: 100%;
					height: 100%;
					max-height: 100%;
					object-fit: cover;
				}
			}
	
			.pp:nth-child(4n) {
				margin-right: 0;
			}
			
			.pp2{
				width: 235px;
				height: 87px;
				background: #F8FDFF;
				border: 1px solid #000000;
				border-radius: 1px;
				margin-right: 30px;
				margin-bottom: 35px;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				padding: 0 10px;
					
				img {
					width: 100%;
					max-width: 100%;
					height: 100%;
					max-height: 100%;
					object-fit: cover;
				}
			}
			
			.pp2:nth-child(5n){
				margin-right: 0;
			}
		}
	}
</style>