<template>
	<div>
		<headerbox :logo="logo" :namNum="navindex"></headerbox>
		<div class="back">
			<img src="../../assets/eventtheme/back.png" alt="">
		</div>
		<div style="background: #F8FDFF;">
			<div class="dh" v-if="columntitle">
				<div>{{columntitle}}</div>
				<div> > </div>
				<div>{{title}}</div>
			</div>
			<div class="title-box">
				<div class="title">
					{{title}}
				</div>
			</div>

			<culture v-if="key == 'City Cultura'"></culture>
			<venueInformation v-if="key == 'Venue Information'"></venueInformation>
			<giveservice v-if="key == 'Media Service Guide'"></giveservice>
			<specimens v-if="key == 'Highlights'"></specimens>
			<sportsman v-if="key == 'Introduction to Athletes'"></sportsman>
			<overview v-if="key == 'Overview of The City'"></overview>
			<emblem v-if="key == 'Event Emblem'"></emblem>
			<news v-if="key == 'News Reporting'"></news>
			<schedule v-if="key == 'Competition Schedule'"></schedule>
			<ticket v-if="key == 'Tickets'"></ticket>
			<venuServices v-if="key == 'Stadium Services'"></venuServices>
			<medal v-if="key == 'Event Medals'"></medal>
			<aboutus v-if="key == 'About Us'"></aboutus>
			<sponsor v-if="key == 'Sponsorship'"></sponsor>
			<commodity v-if="key == 'Licensed Products'"></commodity>
		</div>


		<footerbox></footerbox>
	</div>
</template>

<script>
	import headerbox from './component/header.vue'
	import footerbox from './component/footer.vue'
	
	import culture from './component/page/culture.vue' // 城市文化
	import venueInformation from './component/page/venueInformation.vue' // 场馆信息
	import giveservice from './component/page/giveservice.vue' // 媒体服务指南  
	import specimens from './component/page/specimens.vue' // 赛事集锦 
	import sportsman from './component/page/sportsman.vue' // 运动员介绍 
	import overview from './component/page/overview.vue' // 城市概况 
	import emblem from './component/page/emblem.vue' // 赛事会徽 
	import news from './component/page/news.vue' // 新闻报道 
	import schedule from './component/page/schedule.vue' // 竞赛日程 
	import ticket from './component/page/ticket.vue' // 票务
	import venuServices from './component/page/venuServices.vue' // 赛场服务
	import medal from './component/page/medal.vue' // 赛事奖牌
	import aboutus from './component/page/aboutus.vue' // 关于我们
	import sponsor from './component/page/sponsor.vue' // 赞助商
	import commodity from './component/page/commodity.vue' // 产品明细
	export default {
		name: '',
		components: {
			headerbox,
			footerbox,
			culture,
			venueInformation,
			giveservice,
			specimens,
			sportsman,
			emblem,
			news,
			schedule,
			ticket,
			overview,
			venuServices,
			medal,
			aboutus,
			sponsor,
			commodity
		},
		data() {
			return {
				logo: '',
				key: 10,
				columntitle: '',
				title: '',
				navindex: 0,
				id:''
			}
		},
		created() {
			this.logo = this.$route.query.logo
			this.navindex = this.$route.query.navindex
			this.key = this.$route.query.title.trim()
			this.title = this.$route.query.title.trim()
			this.findAllColumn()
		},
		methods: {
			findAllColumn() {
				let data = {
					id: this.$route.query.navid,
					pid: this.$route.query.navpid
				}
				this.$api.findAllColumn(data).then(res => {
					if (!this.$verification(res.data.data.list)) {
						this.columntitle = res.data.data.list[0].name
					}
					this.title = res.data.data.pid.name

					document.title = res.data.data.pid.name + '-Beijing Sports Competition Management and International Exchange Center'
					this.$meta().refresh()

				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.back {
		height: 545px;
		width: 100%;

		img {
			width: 100%;
			max-width: 100%;
			height: 100%;
			max-height: 100%;
			object-fit: cover;
		}
	}

	.dh {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		font-size: 21px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		padding-top: 33px;
	}

	.title-box {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		padding-top: 50px;

		.title {
			width: 643px;
			height: 65px;
			background: url('../../assets/eventtheme/title5.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			font-size: 42px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #0091FF;
			text-align: center;
		}
	}
</style>