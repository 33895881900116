<template>
	<div>
		<div class="banner-box">
			<img src="../../../../assets/eventtheme/gk.png" alt="">
			<div class="brief">
				<div class="name">Beijing</div>
				<div class="xian"></div>
				<div class="jianj">Beijing, a city with profound cultural heritage and modern vitality, has been built
					for over 3,000 years and established as the capital of China for over 800 years. It’s located at
					39°56’N and 116°20’E, and its time zone is UTC/GMT+08:00. Beijing is in the national climate
					division in the semi-humid warm temperate continental monsoon climate zone. The average temperature
					in December is -5 ℃ to 4 ℃. Beijing is the first city that has hosted both the Summer Olympic Games
					and the Winter Olympic Games in the world. Let’s gather at the National Indoor Stadium and share the
					charm and glory of the Dual-Olympic City together! </div>
			</div>
		</div>

		<div>
			<div class="wz" v-for="(item,index) in date" :key="index">
				<div class="title" @click="jump(item.id)">{{item.title}}</div>
				<div class="brief" @click="jump(item.id)" v-html="getText(item.content)"></div>
				<div class="brief-box">
					<div class="time">[{{item.publishTime | format}}]</div>
					<div class="button" @click="jump(item.id)">
						MORE
						<img src="../../../../assets/eventtheme/more.png" alt="">
					</div>
				</div>
				<div class="xian"></div>
			</div>
			<!-- <div class="wz">
				<div class="title">聚焦国际滑联花样滑冰大奖赛筹办民事实事项目推进 如何交出一份靓丽成绩单？</div>
				<div class="brief">今年是“八人战略” 实施20周年，也是杭州亚（残）运会举办之年。杭州如何精心筹办亚（残）运会 ，坚决打赢亚运攻坚仗？如何推进民生实事项目如期完成，全力写好民生答卷？</div>
				<div class="brief-box">
					<div class="time"> [2023-8-17]</div>
					<div class="button">
						更多
						<img src="../../../../assets/eventtheme/more.png" alt="">
					</div>
				</div>
				<div class="xian"></div>
			</div> -->
		</div>

		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="5" :pageSize="5"></pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				total: 0,
				date: [],
				pageNum: 1,
				pageSize: 5,
			}
		},
		created() {
			this.getArticleListById()
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			currentchange(e) {
				this.pageNum = e
				this.getArticleListById()
			},
			getArticleListById() {
				let data = {
					id: this.$route.query.navid,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
				}
				this.$api.getArticleListById(data).then(res => {
					this.total = res.data.data.total
					this.date = res.data.data.list

					this.date.forEach(item => {
						item.title = item.title.replace(/<br>/g, ' ')
					})
				})
			},
			getText(str) {
				const regExp = /<[^>]+>/g; // 匹配所有HTML标签
				const plainText = str.replace(regExp, ''); // 去除所有标签
				return plainText
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/enversion/details',
					query: {
						subjectid: id,
						logo: this.$route.query.logo,
						navindex: this.$route.query.navindex,
						id: this.$route.query.id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
		}
	}
</script>

<style scoped lang="scss">
	.banner-box {
		width: 1300px;
		height: 614px;
		margin: 63px auto;
		background: url('../../../../assets/eventtheme/hbback5.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: flex;

		img {
			width: 549px;
			height: 470px;
			margin-top: 64px;
			margin-left: 75px;
			border-top-left-radius: 110px;
			border-top-right-radius: 110px;
			border-bottom-left-radius: 110px;
		}

		.brief {
			margin-left: 49px;
			margin-right: 49px;
			

			.name {
				font-size: 33px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				margin-top: 78px;
			}

			.xian {
				width: 81px;
				height: 8px;
				background: #65C5FF;
				opacity: 0.35;
				margin-top: 8px;
			}

			.jianj {
				font-size: 19px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 35px;
				margin-top: 21px;
				// display: -webkit-box;
				// -webkit-box-orient: vertical;
				// -webkit-line-clamp: 11;
				// overflow: hidden;
				// width: 477px;
				text-align: justify;
			}
		}
	}

	.wz {
		width: 1200px;
		margin: 0 auto;

		.title {
			font-size: 31px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			cursor: pointer;
		}

		.title:hover {
			color: #0091FF;
		}

		.brief {
			font-size: 21px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			line-height: 38px;
			margin-top: 39px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			cursor: pointer;
			
		}

		.brief-box {
			display: flex;
			justify-content: space-between;
			margin-top: 59px;
			align-items: flex-start;

			.time {
				font-size: 21px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #AAAAAA;
				margin-top: 17px;
			}

			.button {
				width: 298px;
				height: 56px;
				background: #F8FDFF;
				border: 1px solid #000000;
				border-radius: 28px;
				font-size: 19px;
				font-family: Microsoft YaHei;
				font-weight: 600;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				img {
					width: 30px;
					height: 13px;
					margin-left: 15px;
				}
			}
		}

		.xian {
			width: 1200px;
			height: 1px;
			background: #C5C5C5;
			border-radius: 0px;
			margin-top: 46px;
			margin-bottom: 62px;
		}
	}

	.paging {
		width: 1200px;
		display: flex;
		justify-content: center;
		padding-bottom: 73px;
		margin: 0 auto;
	}
</style>