<template>
	<div>
		<div class="venueInformation-box">
			<div class="name">National Indoor Stadium</div>
			<img src="../../../../assets/eventtheme/guanc.png"alt="">
		</div>

		<div class="brief">
			The National Stadium is truly a "dual Olympic venue". It started construction on May 28, 2005, and was completed in March 2008. The functional requirements were fully in line with the management process and competition standards of major international sports events. It is currently the largest indoor comprehensive sports arena in Asia.
		</div>

		<div>
			<div class="bt">
				<div class="title">
					AREA
				</div>
			</div>

			<div class="xian"></div>

			<div class="content">
				<div class="title" style="margin-right: 56px;margin-top: 22px;">
					To meet the requirements of the Winter Olympic Games events, the National Stadium has under-gone scientific planning and transformation. Cur-rently, the total area of the venue is approximately 98,000 square meters. It is equipped with two ice hockey rinks that meet Olympic standards, allow-ing for quick conversion between ice and land. It is the largest and most advanced indoor venue for cultural and sports activities in the Beijing area.
				</div>
				<img class="img" src="../../../../assets/eventtheme/guanc2.png" alt="">
			</div>

			<div class="bt">
				<div class="title"  style="width: 186px;">
					HONOR
				</div>
			</div>

			<div class="xian"></div>

			<div class="content">
				<div class="title">
					The National Stadium successfully ensured the hosting of 45 ice hockey matches (29 for the Winter Olympics and 16 for the Winter Paralympics) and 256 official training sessions for the 2022 Winter Olympics and Paralympics. It fulfilled the mission of guaranteeing the ice hockey competitions for both the Winter Olympics and the Winter Paralympics. The efforts received high recognition and praise from the International Olympic Committee, International Paralympic Committee, International Ice Hockey Federation, International Paralympic Ice Hockey Committee, Beijing Organizing Committee for the Winter Olympics, as well as athletes and officials from various countries.
				</div>
			</div>

			<div class="bt">
				<div class="title" style="width: 208px;">
					LOCATION
				</div>
			</div>

			<div class="xian"></div>

			<div class="content">
				<div class="img2">
					<img src="../../../../assets/eventtheme/ywguanc1.png" alt="">
				</div>
				<div class="title" style="margin-left: 61px;margin-top: 38px;">National Indoor Stadium, 9 Tianchen East Road, Chaoyang District, Beijing</div>
			</div>
		</div>

		<div style="height: 107px;"></div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {}
		},
		created() {},
		methods: {}
	}
</script>

<style scoped lang="scss">
	.venueInformation-box {
		width: 1300px;
		height: 597px;
		background: #FFFFFF;
		border-radius: 299px;
		margin: 68px auto 0;
	
		img {
			width: 100%;
			height: 100%;
			border-radius: 299px;
		}
	
		.name {
			width: 1194px;
			height: 130px;
			margin-left: 56px;
			background: rgba(24, 17, 35, 0.5);
			border-radius: 299px;
			font-size: 42px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 465px;
			background: url('../../../../assets/eventtheme/bany.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
	}

	.brief {
		width: 1434px;
		margin: 0 auto;
		background: #EAF7FF;
		border-radius: 23px;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		padding: 60px;
		margin-top: 32px;
		line-height: 50px;
		text-align: justify;
	}

	.bt {
		width: 1300px;
		margin: 0 auto;
		margin-top: 92px;
		display: flex;
		justify-content: center;

		.title {
			width: 135px;
			height: 56px;
			font-size: 29px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
			background: url('../../../../assets/eventtheme/title4.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.xian {
		width: 1300px;
		height: 1px;
		background: #5B5B5B;
		border-radius: 0px;
		margin: 0 auto;
		margin-top: 14px;
	}

	.content {
		display: flex;
		width: 1300px;
		margin: 0 auto;
		margin-top: 64px;

		.title {
			font-size: 25px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			line-height: 46px;
			text-align: justify;
		}

		.img{
			width: 633px;
			height: 426px;
		}
		
		.img2{
			width: 533px;
			height: 533px;
			flex-shrink: 0;
			img {
				width: 100%;
				height: 100%;
				border-radius: 30px;
			}
		}
	}
</style>