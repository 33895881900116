<template>
	<div>
		<div class="box">
			<img src="../../../../assets/eventtheme/logo.png" alt="" class="logo">
		</div>
		<div class="brief" v-html="datedan.content || ''">
			
		</div>
		<!-- <div v-if="datedan.contentFileUrl && JSON.parse(datedan.contentFileUrl).length>0">
		  <ul>
		    <li v-for="(item,index) in JSON.parse(datedan.contentFileUrl)" :key="index" style="margin-bottom: 10px"><a :href="item.url" style="color: #2d52a0">
		      <img src="/images/fujian.png" alt="" style="width: 20px">
		      {{item.name}}
		    </a></li>
		  </ul>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				datedan:{}
			}
		},
		created() {
			this.getArticleById()
		},
		methods: {
			getArticleById(){
				this.$api.getArticleById({id:this.$route.query.navid}).then(res=>{
					this.datedan = res.data.data
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.box {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		height: 523px;
		background: #FFFFFF;
		border-radius: 33px;
		margin-top: 89px;
	}
	
	.logo {
		width: 678px;
		height: 407px;
		margin-top: 56px;
	}
	
	.brief{
		width: 1200px;
		margin: 0 auto;
		font-size: 33px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		line-height: 71px;
		padding: 103px 0;
		::v-deep p{
			::v-deep img{
				width: 100%;
				max-width: 100%;
				height: 100%;
				max-height: 100%;
				object-fit: cover;
			}
		}
	}
</style>